<template>
  <v-container id="dashboard" fluid tag="section">
    <template>
      <v-btn
        color="primary"
        dark
        class="mt-4 mb-4"
        v-bind="attrs"
        v-on="on"
        @click="clickAddNewBtn"
      >
        <v-icon class="mr-2">mdi-account-multiple-outline</v-icon>
        ADD NEW USER
      </v-btn>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        hide-default-footer
        :items-per-page="itemsPerPage"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        class="elevation-1"
      >
        <template v-slot:item.blocked="{ item }">
          <v-chip :color="item.blocked === 0 ? '#4CAF50' : '#AB1713'" dark>
            <v-icon v-if="item.blocked === 0">mdi-check</v-icon>
            <v-icon v-if="item.blocked !== 0">mdi-window-close</v-icon>
          </v-chip>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="1100px">
            <v-card>
              <v-card-title class="elevation-5 pb-3 text-left">
                <span class="h6 font-weight-bold modal-header-text">
                  <label>{{ formTitle }}</label></span
                >
              </v-card-title>

              <v-card-text>
                <v-form v-model="validate" class="mt-6">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >First name</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.firstname"
                              label="First name"
                              :rules="[rules.required]"
                              required
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >Last Name</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.lastname"
                              label="Last Name"
                              :rules="[rules.required]"
                              solo
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >User Name</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.username"
                              label="User name"
                              dense
                              solo
                              :rules="[rules.required]"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >Email</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                              :rules="[rules.required, rules.email]"
                              required
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >User Type</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-radio-group
                              v-model="editedItem.user_type"
                              mandatory
                              row
                              solo
                              dense
                              class="mt-3"
                            >
                              <v-radio label="Admin" value="admin"></v-radio>
                              <v-radio label="User" value="user"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <!-- <p class="mb-0 caption"></p> -->
                      </v-col>
                      <v-col v-if="editedIndex === -1" cols="12" md="6">
                      </v-col>
                      <v-col v-if="editedIndex !== -1" cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >Blocked</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-checkbox
                              class="mt-1"
                              v-model="editedItem.blocked"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <!-- <p class="mb-0 caption"></p> -->
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >Password</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.password"
                              label="Password"
                              type="password"
                              solo
                              dense
                              :rules="[
                                rules.containString,
                                rules.special,
                                rules.counter,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" sm="4" class="mt-3">
                            <v-text style="color: black; font-size: 16px"
                              >Confirm Password</v-text
                            >
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="editedItem.confirm"
                              label="Confirm Password"
                              :rules="[
                                rules.confirmpassword,
                                rules.containString,
                                rules.special,
                                rules.counter,
                              ]"
                              solo
                              type="password"
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions class="pb-10 align-center m-auto justify-center">
                <!-- <v-spacer></v-spacer> -->
                <v-btn color="primary" @click="save" :loading="isLoading">
                  {{ editedIndex !== -1 ? "Update" : "Create" }}</v-btn
                >
                <v-btn color="default" @click="close"> Cancel </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="error" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2 action_icon" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon class="action_icon" small @click="deleteItem(item)">
            mdi-delete-outline
          </v-icon>
        </template>
      </v-data-table>
      <Pagination
        :page.sync="page"
        :itemsPerPage.sync="itemsPerPage"
        :pages="pages"
        :pageItems="pageItems"
        :totalNumberOfItems="totalNumberOfItems"
      />
    </template>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  APIdoGetUsers,
  APIdoRemoveUser,
  APIdoAddUser,
  APIdoUpdateUser,
} from "@/api/common/api";
import { mapGetters } from "vuex";
import Pagination from "../component/pagination/";

export default {
  name: "Users",

  data() {
    return {
      isLoading: false,
      totalNumberOfItems: 0,
      page: 1,
      itemsPerPage: 20,
      pages: 5,
      pageItems: [20, 50, 100],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      selected: [],
      validate: false,
      passwordValidate: true,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        confirmpassword: (value) => {
          return this.passwordCheck() || "Confirm password is not match";
        },
        special: (value) => {
          return /[!@#\$%\^&\*]/.test(value) || "At least 1 special symbol";
        },
        containString: (value) => {
          //For latin letters only
          return /[A-Za-z]/.test(value) || "At least one letter";
        },
        number: (value) => {
          return /\d/.test(value) || "At least 1 number";
        },
      },
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      headers: [
        {
          text: "User name",
          value: "username",
        },
        { text: "FIRST NAME", value: "firstname" },
        { text: "LAST NAME", value: "lastname" },
        { text: "EMAIL", value: "email" },
        { text: "USER TYPE", value: "user_type" },
        { text: "access", value: "blocked" },
        { text: "CREATED ON", value: "createdon" },
        { text: "", value: "actions" },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Create new user" : "Edit User Details";
    },
  },

  mounted() {
    if (this.getUserDetails.user_type !== "admin") this.$router.push("/");
    this.getUsers(this.page, this.itemsPerPage);
  },
  components: {
    Pagination,
  },
  methods: {
    getUsers(page, limit) {
      this.isLoading = true;
      this.tableData = [];

      APIdoGetUsers(page, limit)
        .then((response) => {
          this.isLoading = false;
          this.pages = response.data.pages;
          this.totalNumberOfItems = response.data.total;
          this.tableData = response.data.results;
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
    refresh() {
      this.page = 1;
      this.itemsPerPage = 20;
      this.getUsers(this.page, this.itemsPerPage);
    },
    handleItemsPerPageChange() {
      this.page = 1;
      this.getUsers(this.page, this.itemsPerPage);
    },
    handlePageChange() {
      this.page = parseInt(this.page);
      this.getUsers(this.page, this.itemsPerPage);
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      const postData = {
        username: this.editedItem.username,
        firstname: this.editedItem.firstname,
        lastname: this.editedItem.lastname,
        email: this.editedItem.email,
        user_type: this.editedItem.user_type,
        password: this.editedItem.password,
        confirm: this.editedItem.confirm,
        id: this.editedItem.id,
      };
      if (this.editedIndex !== -1) {
        postData.blocked = this.editedItem.blocked;
      }
      if (this.editedIndex === -1 && this.validate) {
        this.isLoading = true;

        APIdoAddUser(postData)
          .then((response) => {
            this.notification = true;
            this.notificationTxt = "Successfully Created";
            this.notificationColor = "success";
            this.tableData.push(this.editedItem);
            this.isLoading = false;
            this.refresh();
            this.close();
          })
          .catch((err) => {
            this.isLoading = false;
            this.notification = true;
            this.notificationTxt = err.response.data.error.details[0].message;
            this.notificationColor = "error";
          });
      }

      if (this.editedIndex !== -1 && this.validate) {
        this.isLoading = true;

        APIdoUpdateUser(postData)
          .then((response) => {
            this.notification = true;
            this.notificationTxt = "Successfully Updated";
            this.notificationColor = "success";
            this.getUsers(this.page, this.itemsPerPage);
            this.close();
          })
          .catch((err) => {
            this.isLoading = false;
            this.notification = true;
            this.notificationTxt = err.response.data.error.details[0].message;
            this.notificationColor = "error";
          });
      }
    },
    passwordCheck() {
      if (
        (this.editedItem.password === "" && this.editedItem.confirm === "") ||
        this.editedItem.password === this.editedItem.confirm
      ) {
        return true;
      } else {
        return false;
      }
    },
    deleteItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.tableData.splice(this.editedIndex, 1);
      APIdoRemoveUser(this.editedItem.id)
        .then(() => {
          this.notification = true;
          this.notificationTxt = "Successfully Removed";
          this.notificationColor = "success";
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
      this.closeDelete();
    },
    clickAddNewBtn() {
      this.dialog = true;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
